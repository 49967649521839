const axios = require("axios");
const { ConcurrencyManager } = require("axios-concurrency");

const request = axios.create();

export const api_config = {
    cache_server: null
}

request.interceptors.request.use(function (config) {
    const cache_server = api_config.cache_server;
    console.log(cache_server)
    if (cache_server && cache_server.trim().length) {
        config.url = cache_server + config.url.replace('://', '/');
    }
    console.log(config.url)
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// a concurrency parameter of 1 makes all api requests secuential
const MAX_CONCURRENT_REQUESTS = 2;

// init your manager.
const manager = ConcurrencyManager(request, MAX_CONCURRENT_REQUESTS);

export default request;