export const bqCountry = `
Montserrat
South Sudan
Albania
Gambia
Svalbard & Jan Mayen
Lesotho
Somalia
Peru
Antigua & Barbuda
Kiribati
Ghana
Åland Islands
Palau
Kazakhstan
Algeria
Chad
Rwanda
Isle of Man
Guinea-Bissau
St. Barthélemy
Vanuatu
Equatorial Guinea
Anguilla
French Guiana
Dominica
Guam
Bahamas
Malta
Moldova
Costa Rica
Ethiopia
Guinea
Guernsey
Djibouti
British Indian Ocean Territory
Sierra Leone
Haiti
U.S. Virgin Islands
Georgia
Belgium
Malaysia
Australia
Eswatini
Comoros
Solomon Islands
New Caledonia
Nigeria
Singapore
Malawi
Mauritania
Mozambique
Czechia
Croatia
Ecuador
Guatemala
British Virgin Islands
Jersey
Armenia
Colombia
Panama
Seychelles
Tajikistan
Japan
Greenland
Burundi
São Tomé & Príncipe
Mali
Turks & Caicos Islands
Faroe Islands
Uganda
Tunisia
Uzbekistan
Switzerland
New Zealand
Taiwan
Caribbean Netherlands
United Kingdom
Pakistan
Palestine
Bahrain
American Samoa
Côte d’Ivoire
Slovakia
Egypt
Denmark
Congo - Kinshasa
St. Vincent & Grenadines
Indonesia
Tanzania
Norfolk Island
Russia
Micronesia
United Arab Emirates
France
Congo - Brazzaville
Liechtenstein
Monaco
Guyana
Qatar
El Salvador
India
Saudi Arabia
St. Martin
Yemen
Paraguay
Senegal
Germany
Iceland
Western Sahara
China
Uruguay
Tuvalu
Gibraltar
Cook Islands
Macao
Trinidad & Tobago
Martinique
Vietnam
Bulgaria
Turkmenistan
Montenegro
Bosnia & Herzegovina
Andorra
French Polynesia
Angola
Burkina Faso
United States
Réunion
Madagascar
Norway
Hungary
Portugal
Guadeloupe
Morocco
Libya
Serbia
Nepal
Jamaica
Lithuania
Papua New Guinea
Chile
San Marino
Belarus
Mauritius
Finland
Brunei
Eritrea
Kuwait
Liberia
North Macedonia
Iran
Oman
Mexico
Niger
Timor-Leste
Tonga
Turkey
Zambia
Vatican City
Cuba
Afghanistan
Botswana
Cayman Islands
Latvia
Zimbabwe
Samoa
Jordan
Cyprus
Slovenia
Sri Lanka
Togo
Nauru
Bermuda
Gabon
Namibia
Kenya
Belize
South Africa
Bhutan
Romania
Ukraine
Bangladesh
South Korea
St. Lucia
Curaçao
Kosovo
Italy
St. Kitts & Nevis
Venezuela
Poland
Cameroon
Mayotte
Maldives
Kyrgyzstan
St. Pierre & Miquelon
Barbados
Lebanon
Cambodia
Canada
Mongolia
Austria
St. Helena
Suriname
Honduras
Brazil
Northern Mariana Islands
Falkland Islands (Islas Malvinas)
Sint Maarten
Greece
Grenada
Azerbaijan
Sweden
Fiji
Ireland
Cape Verde
Benin
Syria
Israel
Thailand
Central African Republic
Laos
Marshall Islands
Bolivia
Estonia
Netherlands
Luxembourg
Spain
Nicaragua
Sudan
Iraq
Aruba
Myanmar (Burma)
Argentina
Hong Kong
Dominican Republic
Puerto Rico
Philippines
`.split('\n')
.map(country => country.trim())
.map(country => ({
  "label": country,
  "value": country,
}));

console.log(bqCountry)