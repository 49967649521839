import {useState, useEffect} from "react";
import useLocalforage from '@rooks/use-localstorage';
import { useQueryParam, ObjectParam, StringParam } from 'use-query-params';
import _ from 'lodash';
import localforage from 'localforage';
import Papa from "papaparse";

export const useSavedState = (key, defaultValue) => {
    const [value, setValue] = useState(key, defaultValue);
    const setSavedValue = (value) => {
        localforage.setItem(key, value);
        setValue(value);
    }
    useEffect(async () => {
        const value = await localforage.getItem(key);
        setValue(value);
    }, []);
    const removeSavedValue = async () => {
        setValue(null);
        await localforage.removeItem(key);
    }
    return [value, setSavedValue, removeSavedValue];
}

export const useInput = (key, defaultValue) => {
    const [value, setValue] = useState(key, defaultValue);
    const onChange = (event) => {
        setValue(event.target.value);
    };
    return [value, onChange];
}

export const useCsvFileInput = (key, defaultValue) => {
    const [value, setValue] = useState(defaultValue);
    const onChange = (event) => {
        try {
            const reader = new FileReader();
            reader.onload = async (event) => {
                if(event.target.readyState != 2) return;
                if(event.target.error) {
                    alert('Error while reading file');
                    return;
                }

                const result = await Papa.parse(event.target.result, {
                    header: true
                });

                setValue(result.data);
            };
            reader.readAsText(event.target.files[0]);
        } catch(error) {
            console.error(error);
        }
    };
    return [value, onChange];
}

export const useLocalStorageInput = (key, defaultValue) => {
    const [value, setValue] = useLocalforage(key, defaultValue);
    const onChange = (event) => {
        setValue(event.target.value);
    };
    return [value, onChange];
}

export const useLocalStorageQueryInput = (key, defaultValue, paramType = StringParam) => {
    const [value, onInputChange] = useLocalStorageInput(key, defaultValue);
    const [query, setQuery] = useQueryParam(key, paramType);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramValue = urlParams.get(key);
        onInputChange({
            target: {
                value: paramValue
            }
        });
    }, []);

    useEffect(() => {
        setQuery(value);
    }, [value, onInputChange]);

    return [value, onInputChange];
}

export const useQueryInput = (key, defaultValue, paramType = StringParam) => {
    const [value, onInputChange] = useInput(defaultValue);
    const [query, setQuery] = useQueryParam(key, paramType);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramValue = urlParams.get(key);
        onInputChange({
            target: {
                value: paramValue
            }
        });
    }, []);

    useEffect(() => {
        setQuery(value);
    }, [value, onInputChange]);

    return [value, onInputChange];
}

export const useQuery = (key, defaultValue, paramType = ObjectParam) => {
    const [value, setValue] = useState(defaultValue);
    const [query, setQuery] = useQueryParam(key, paramType);

    useEffect(() => {
        setValue(query);
    }, []);

    useEffect(() => {
        setQuery(value);
    }, [value, setValue]);

    return [value, setValue];
}