import _ from 'lodash'
import moment from 'moment';
import {parse} from "json2csv";
const { getCode } = require('./utils/country-list');

const multiGroupBy = (data, [prop, ...props], processData = x => x) => {
    if (!prop) return processData(data);
    return _.chain(data)
        .groupBy(prop)
        .mapValues(records => multiGroupBy(records, props, processData))
        .value();
}

const cummulativeFollowupEvent = (followup_event, users) => {
    return followup_event
        .reduce((result, value, index) => {
            return [
                ...result,
                value.count ? value.count + (result[index - 1] || 0) : '-'
            ]
        }, [])
        .map(count => count === '-' ? count : users ? (count / users) : '-');
};

const getPath = (obj, defaultValue, path, ...paths) => {
    if ( _.isUndefined(obj[path]) ) return defaultValue;
    else if (paths.length) return getPath(obj[path], defaultValue, ...paths);
    else return obj[path];
}

const formatNumberCohort = number => typeof (number) !== 'number' ? number : (Math.round(number * 10) / 10).toFixed(1);
const formatNumberRetention = number => typeof (number) !== 'number' ? number : (Math.round(number * 1000) / 10).toFixed(1) + '%';

export const ABigQuery = (data, startDate, numberOfDays) => {
    const eventNames = [
        'RPM', 
        'AdRPM',
        // 'IapRPM'
    ];

    /** 
        ABVersion: "AB_20200327_v211"
        InstallDate:
            value: "2020-04-03"
        Day: 2
        AdsRevenue: 10.14880891113095
        IAPRevenue: 1.7970269653564286
        TotalRevenue: 11.94583587648738
    */

    const records = data.map(datum => ({
        ...datum,
        AbEvent: datum.ABVersion,
        InstallDate: datum.InstallDate.value.replace(/-/g,''),
        DaysFromInstall: datum.Day,
    }));

    console.log(records)

    const AbEvents = _.chain(records)
        .map(data => data.AbEvent)
        .sort().uniq().value();
    
    const DaysFromInstalls = _.range(numberOfDays);

    console.log(AbEvents, DaysFromInstalls)

    console.log('Starting cohort')

    const defaultEvent = {
        count: 0,
        uniques: 0, 
    };

    const buildCohortData = (field) => {
        return AbEvents.map(AbEvent => {
            return {
                AB_event: AbEvent,
                data: DaysFromInstalls.map(installOffsetDay => {
                    const installDate = moment(startDate).add(installOffsetDay, "day");
                    const firstRecord = _.find(records, {
                        AbEvent,
                        InstallDate: installDate.format('YYYYMMDD'),
                        DaysFromInstall: 0,
                    });
                    
                    return {
                        first_event: firstRecord ? {
                            count: firstRecord[field],
                            uniques: firstRecord.TotalUsers,
                        } : defaultEvent,
                        followup_event: _.chain(records)
                            .filter({
                                AbEvent,
                                InstallDate: installDate.format('YYYYMMDD'),
                            })
                            .sortBy('DaysFromInstall')
                            .map((record) => ({
                                count: record[field],
                                uniques: record.TotalUsers
                            }))
                            .value(),
                    };
                })
            };
        });
    }

    const cohortResults = [
        buildCohortData('TotalRevenue'),
        buildCohortData('AdsRevenue'),
        // buildCohortData('IAPRevenue')
    ];

    console.log(cohortResults)

    const sampleCohort = cohortResults[0];

    const columnsDays = _.range(Math.max(numberOfDays, 8));

    /** Merge show reward & full ad */
    const mergedData = sampleCohort.map(({AB_event, data}, eventIndex) => {
        const result = columnsDays.map(day => {
            const sampleData = data[day];
            if (sampleData) {
                const users = sampleData.first_event.uniques;

                return eventNames.reduce((data, eventName, index) => ({
                    ...data,
                    [eventName]: (() => {
                        const cohortByEvent = cohortResults[index][eventIndex];
                        const cohortDailyByEvent = cohortResults[index][eventIndex].data[day];
                        const retentionUsers = cohortDailyByEvent.first_event.uniques;

                        return cohortByEvent.type === 'retention'
                            ? cohortDailyByEvent.followup_event.map(followEvt => retentionUsers ? (followEvt.uniques / retentionUsers || '-') : '-')
                            : cummulativeFollowupEvent(cohortDailyByEvent.followup_event, users)
                    })()
                }), {
                    AB_event: '',
                    date: moment(startDate).add(day, "day").format('MM-DD'),
                    users,
                });
            } else {
                return {
                    AB_event: ""
                }
            }
        });

        const totalUsers = _.sumBy(data, x => x.first_event.uniques);

        return [
            eventNames.reduce((data, eventName, index) => ({
                ...data,
                [eventName]: result[0][eventName].map((row, resultIndex) => {
                    return (_.sumBy(result, x => x[eventName] && x[eventName][resultIndex] && x[eventName][resultIndex] !== '-'
                        ? x[eventName][resultIndex] * x.users : 0)
                        / _.sumBy(result, x => x[eventName] && x[eventName][resultIndex] && x[eventName][resultIndex] !== '-'
                            ? x.users : 0) || '-');
                })
            }), {
                AB_event,
                users: totalUsers
            }),
            ...result
        ]
    });

    const processed = _.chain(mergedData)
        .flatten()
        .map(({AB_event, date, users, ...cohortResults}) => {
            return {
                AB_event,
                date,
                users,
                ..._.mapValues(cohortResults, (result, type) => ({
                    ...result.map(x =>
                        type === 'retention' ? formatNumberRetention(x) : formatNumberCohort(x))
                }))
            }
        })
        .value();

    return parse([
        ...processed
    ], {
        delimiter: ';',
        fields: _.flattenDeep([
            'AB_event',
            'date',
            'users',
            _.map(eventNames, name => [
                ' ',
                ...columnsDays.slice(0, columnsDays.length - 1).map(day => `${name}.${day}`),
            ])
        ])
    });
}

