import qs from 'qs';
import _ from 'lodash';
import Axios from 'axios';
import fuzzy from 'fuzzy';
import moment from "moment";
import ReactDOM from "react-dom";
import React, {useState, useRef, useEffect} from "react";

import Select from 'react-select';
import {Helmet} from "react-helmet";
import AsyncSelect from 'react-select/async';
import {CsvToHtmlTable} from 'react-csv-to-table';
import useLocalforage from '@rooks/use-localstorage';
import { ConcurrencyManager } from "axios-concurrency";
import {parse} from "json2csv";

import "./styles.css";
import { ABigQuery } from "./ABigQuery";
import {CopyElementContent} from "./utils/CopyElementContent";
import { ApplovinUserAdRevenue, ApplovinFacebookPropotion } from "./Applovin";

import { ProvideAuth, useAuth } from "./use-auth.js";

import {
    useSavedState,
    useInput,
    useCsvFileInput,
    useLocalStorageInput,
    useLocalStorageQueryInput,
    useQueryInput,
    useQuery,
} from './hooks';
import { bqCountry } from './bqCountry';

const progressPromise = async (promises, onProgress) => {
    let progress = 0;
    const totalProgress = promises.length;

    return Promise.all(
        promises.map(async promise => {
            const result = await promise;
            progress++;
            onProgress(progress * 100 / totalProgress);
            return result;
        })
    );
}

const progressPromiseFn = async (promiseFns, onProgress) => {
    const totalProgress = promiseFns.length;
    const progresses = _.range(totalProgress).map(value => 0);

    const onProgressPartial = index => progress => {
        progresses[index] = progress;
        onProgress(_.sum(progresses) * 100/totalProgress);
    }

    const results = [];

    for (let index = 0; index < promiseFns.length; index++) {
        const promiseFn = promiseFns[index];
        
        let onProgress = onProgressPartial(index);
        const result = await promiseFn(onProgress);
        onProgress(1);

        results.push(result);
    }

    return results;
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

function App() {
    const {
        user,
        signin,
        signout,
    } = useAuth();

    const [failDates, setFailDates] = useState([]);
    const [log, setLog] = useState([]);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const execCopy = () => CopyElementContent();

    const [data, setData, rmData] = useLocalforage("csv", null);

    const [unitedDataServer, onUnitedDataServerInputChange] = useLocalStorageInput('unitedDataServer', '//uniteddata.game.ieccorp.vn');

    const [games, setGames] = useState([]);
    const [gameConfig, setGameConfig] = useState();
    const [platform, setPlatform] = useQuery('platform', {
        value: 'android',
        label: 'Android',
    });
    const [dates, setDates] = useState('dates', []);
    const [startDate, setStartDate] = useState('startDate', '');
    const [endDateInstall, setEndDateInstall] = useState('endDateInstall', '');
    const [endDateRevenue, setEndDateRevenue] = useState('endDateRevenue', '');

    const createInstance = async () => {
        const token = await user.getIdToken(true);
        const instance = Axios.create({
            baseURL: unitedDataServer,
            headers: {
                Authorization: 'Bearer ' + token,
                useCache: "false"
            }
        });
        ConcurrencyManager(instance, 2);
        return instance;
    }

    useEffect(() => {
        if (!user || !gameConfig || !platform) return;
        setLoading(true);
        (async () => {
            const instance = await createInstance();
            const { data } = await instance.get('roas/dates?' + qs.stringify({
                appId: gameConfig.value.appId,
                platform: platform.value
            }));
            setDates(data.map(date => ({
                value: date,
                label: date
            })));
            setLoading(false);
        })();
    }, [gameConfig, platform]);

    const startQuery = async () => {
        const instance = await createInstance();

        rmData(null);
        setLog([]);
        setFailDates([]);
        setLoading(true);
        setProgress(0);

        const currentLog = [];
        const onLog = logString => {
            console.log(logString);
            currentLog.push(logString);
            setLog(currentLog);
        };

        try {
            const { data } = await instance.get('roas/query?' + qs.stringify({
                appId: gameConfig.value.appId,
                platform: platform.value,
                startDate: startDate.value,
                endDateInstall: endDateInstall.value,
                endDateRevenue: endDateRevenue.value,
            }));

            onLog(`Success`);

            console.log(data);

            const result = parse(
                data.map(datum => ({
                    'Network': datum.Network,
                    'Country': datum.Country,
                    'Revenue': datum.Revenue,
                    'Propotion': datum.Propotion,
                    'Estimated Revenue': datum.EstimatedRevenue,
                })), 
                {
                    delimiter: ';'
                }
            );

            setLoading(false);
            setData(result);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }

    const gameListPromise = async (inputValue) => {
        const instance = await createInstance();
        let list = games;
        if (!list.length) {
            const { data } = await instance.get(`apps`);
            list = _.map(data, (game, key) => ({
                value: {
                    ...game,
                    appId: key
                },
                label: game.name
            }));
            setGames(list);
        }

        console.log(list)

        setGameConfig(gameConfig)

        return fuzzy.filter(inputValue, list, {
            extract: el => el.label
        }).map(el => el.original);
    }

    if (!user) {
        return <button onClick={signin}>Sign in</button>
    }

    return (
        <div className="App">
            <Helmet>
                <meta charSet="utf-8" />
                <title>ROAS</title>
            </Helmet>
            <aside id="sidebar">
                <h1>ROAS</h1>
                <p>
                    <label>Server: </label>
                    <input type="text" value={unitedDataServer} onChange={onUnitedDataServerInputChange}/>
                </p>
                {!unitedDataServer ? null : <div>
                    <p>
                        <AsyncSelect placeholder="Select Game..." cacheOptions defaultOptions 
                            loadOptions={gameListPromise} 
                            value={gameConfig} 
                            onChange={setGameConfig}
                            isDisabled={loading}
                        />
                    </p>
                    <p>
                        <Select placeholder="Select Platform..." value={platform} onChange={setPlatform} options={[
                            {
                                value: "android",
                                label: "Android"
                            },
                            {
                                value: "ios",
                                label: "iOS"
                            }
                        ]}/>
                    </p>
                    <p>
                        <label>Start Date</label>
                        <Select placeholder="Select Start Date..." value={startDate} onChange={setStartDate} 
                            options={dates} isDisabled={loading || !gameConfig || !dates || !platform}
                            isLoading={loading}
                        />
                    </p>
                    <p>
                        <label>End Date Install</label>
                        <Select placeholder="Select End Date Install..." value={endDateInstall} onChange={setEndDateInstall} 
                            options={!dates || !dates.length || !startDate || !startDate.value ? [] : _.filter(dates, date => {
                                if (startDate && startDate.value) {
                                    if (moment(date.value).diff(moment(startDate.value)) >= 0) return true;
                                } else return false;
                            })} 
                            isDisabled={loading || !gameConfig || !dates || !startDate || !startDate.value}
                            isLoading={loading}
                        />
                    </p>
                    <p>
                    <label>End Date Revenue</label>
                        <Select placeholder="Select End Date Revenue..." value={endDateRevenue} onChange={setEndDateRevenue} 
                            options={!dates || !dates.length || !endDateInstall || !endDateInstall.value ? [] : _.filter(dates, date => {
                                if (endDateInstall && endDateInstall.value) {
                                    if (moment(date.value).diff(moment(endDateInstall.value)) >= 0) return true;
                                } else return false;
                            })} 
                            isDisabled={loading || !gameConfig || !dates || !endDateInstall || !endDateInstall.value}
                            isLoading={loading}
                        />
                    </p>
                    <p></p>
                    <button id={"buttonStartQuery"} onClick={loading ? null : startQuery}>{
                        loading ?
                            `${progress.toFixed(2)}%` :
                            "Start Query"
                    }</button>
                    &emsp;
                    <button onClick={execCopy}>Copy Table</button>
                    <p></p>
                    <p></p>
                    <button onClick={signout}>Sign out</button>
                    <p></p>
                    <p>{
                        failDates.map(({date, type}, index) => (
                            <p className="error" key={index}>Fail to load {type} {date.format('YYYY-MM-DD')}</p>
                        ))
                    }</p>
                    <p>{
                        log.map((logString, index) => (
                            <p className="log" key={index}>{logString}</p>
                        ))
                    }</p>
                    <p></p>
                </div>}
            </aside>
            <div className="data">
                
                {!data ? null : <CsvToHtmlTable
                    data={data.replace(/"/g, "")}
                    csvDelimiter=";"
                />}
            </div>
        </div>
    );
}

const FirebaseApp = () => (
    <ProvideAuth>
        <App />
    </ProvideAuth>
);

const rootElement = document.getElementById("root");
ReactDOM.render(<FirebaseApp/>, rootElement);
