const _ = require("lodash");
const Axios = require("./api").default;
const qs = require("qs");
const csvtojsonV2 = require("csvtojson");
const Papa = require('papaparse');

export const ApplovinUserAdRevenue = async (mediator, instance, appId, platform, date, onFail, onLog, onProgress) => {
    const URL = `${mediator}/userRevenue?` + qs.stringify({
        appId,
        date: date.format('YYYY-MM-DD'),
        platform
    });
    try {
        if (mediator == 'ironsrc') {
            onLog(`Applovin Starting Download ${date.format('YYYY-MM-DD')}`);            
            const {data: csvString} = await instance.get(URL, {
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = progressEvent.loaded / progressEvent.total;
                    onProgress(percentCompleted);
                }
            });
            onLog(`Applovin Success Download ${date.format('YYYY-MM-DD')}`);
            // const csvData = await csvtojsonV2().fromString(csvString);
            const {data: results} = await Papa.parse(csvString, {
                header: true
            })
            onLog(`Applovin Success Parse ${date.format('YYYY-MM-DD')}`);
            
            return results.map(record => ({
                IDFA: record.advertising_id,
                IDFV: record.advertising_id,
                Revenue: record.revenue,
                Impressions: record.impression,
                Date: date
            }));
        } else {
            const {data: {url}} = await instance.get(URL);
            onLog(`Applovin Starting Download ${date.format('YYYY-MM-DD')}`);
            const {data: csvString} = await instance.get('download-proxy?' + qs.stringify({url}), {
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = progressEvent.loaded / progressEvent.total;
                    onProgress(percentCompleted);
                }
            });
            onLog(`Applovin Success Download ${date.format('YYYY-MM-DD')}`);
            // const csvData = await csvtojsonV2().fromString(csvString);
            const {data: results} = await Papa.parse(csvString, {
                header: true
            })
            onLog(`Applovin Success Parse ${date.format('YYYY-MM-DD')}`);
            return results.map(record => ({
                ...record,
                Date: date
            }));
        }
        
    } catch(error) {
        onLog(`Applovin Fail Parse ${date.format('YYYY-MM-DD')}`);
        console.error(error);
        onFail(date, error);
        return [];
    }
}
/** CALCULATE FACEBOOK BIDDING REV PROPOTION OVER TOTAL REVENUE PER COUNTRY */
export const ApplovinFacebookPropotion = async (mediator, instance, appId, platform, startDate, endDate, onFail, onLog) => {
    const { data } = await instance.get(`${mediator}/facebookPropotion?` + qs.stringify({
        appId,
        platform,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
    }))

    return data;
}