const countryMap = {
    'curaçao': 'CW',
    'sint maarten': 'SX',
    'st. kitts & nevis' : 'KN',
    'st. pierre & miquelon' : 'PM',
    'st. vincent & grenadines' : 'VC',
    'st. barthelemy' : 'BL',
    'st. helena' : 'SH',
    'st. kitts and nevis' : 'KN',
    'st. lucia' : 'LC',
    'st. martin' : 'MF',
    'st. pierre and miquelon' : 'PM',
    'st. vincent and grenadines' : 'VC',
    'antigua & barbuda' : 'AG',
    'bosnia & herzegovina' : 'BA',
    'saint kitts & nevis' : 'KN',
    'saint pierre & miquelon' : 'PM',
    'saint vincent & grenadines' : 'VC',
    'sao tome & principe' : 'ST',
    'south georgia & sandwich isl.' : 'GS',
    'svalbard & jan mayen' : 'SJ',
    'trinidad & tobago' : 'TT',
    'turks & caicos islands' : 'TC',
    'wallis & futuna' : 'WF',
    'kosovo': 'XK',
    'afghanistan' : 'AF',
    'aland islands' : 'AX',
    'albania' : 'AL',
    'algeria' : 'DZ',
    'american samoa' : 'AS',
    'andorra' : 'AD',
    'angola' : 'AO',
    'anguilla' : 'AI',
    'antarctica' : 'AQ',
    'antigua and barbuda' : 'AG',
    'argentina' : 'AR',
    'armenia' : 'AM',
    'aruba' : 'AW',
    'australia' : 'AU',
    'austria' : 'AT',
    'azerbaijan' : 'AZ',
    'bahamas' : 'BS',
    'bahrain' : 'BH',
    'bangladesh' : 'BD',
    'barbados' : 'BB',
    'belarus' : 'BY',
    'belgium' : 'BE',
    'belize' : 'BZ',
    'benin' : 'BJ',
    'bermuda' : 'BM',
    'bhutan' : 'BT',
    'bolivia' : 'BO',
    'bosnia and herzegovina' : 'BA',
    'bosnia & herzegovina' : 'BA',
    'botswana' : 'BW',
    'bouvet island' : 'BV',
    'brazil' : 'BR',
    'british indian ocean territory' : 'IO',
    'brunei darussalam' : 'BN',
    'brunei' : 'BN',
    'bulgaria' : 'BG',
    'burkina faso' : 'BF',
    'burundi' : 'BI',
    'cambodia' : 'KH',
    'cameroon' : 'CM',
    'canada' : 'CA',
    'cape verde' : 'CV',
    'cayman islands' : 'KY',
    'central african republic' : 'CF',
    'chad' : 'TD',
    'chile' : 'CL',
    'china' : 'CN',
    'christmas island' : 'CX',
    'cocos (keeling) islands' : 'CC',
    'colombia' : 'CO',
    'comoros' : 'KM',
    'congo' : 'CG',
    'congo - kinshasa' : 'CG',
    'congo, democratic republic' : 'CD',
    'cook islands' : 'CK',
    'costa rica' : 'CR',
    'cote d\'ivoire' : 'CI',
    'côte d’ivoire': 'CI',
    'croatia' : 'HR',
    'cuba' : 'CU',
    'cyprus' : 'CY',
    'czech republic' : 'CZ',
    'czechia' : 'CZ',
    'denmark' : 'DK',
    'djibouti' : 'DJ',
    'dominica' : 'DM',
    'dominican republic' : 'DO',
    'ecuador' : 'EC',
    'egypt' : 'EG',
    'el salvador' : 'SV',
    'equatorial guinea' : 'GQ',
    'eritrea' : 'ER',
    'estonia' : 'EE',
    'ethiopia' : 'ET',
    'falkland islands (malvinas)' : 'FK',
    'faroe islands' : 'FO',
    'fiji' : 'FJ',
    'finland' : 'FI',
    'france' : 'FR',
    'french guiana' : 'GF',
    'french polynesia' : 'PF',
    'french southern territories' : 'TF',
    'gabon' : 'GA',
    'gambia' : 'GM',
    'georgia' : 'GE',
    'germany' : 'DE',
    'ghana' : 'GH',
    'gibraltar' : 'GI',
    'greece' : 'GR',
    'greenland' : 'GL',
    'grenada' : 'GD',
    'guadeloupe' : 'GP',
    'guam' : 'GU',
    'guatemala' : 'GT',
    'guernsey' : 'GG',
    'guinea' : 'GN',
    'guinea-bissau' : 'GW',
    'guyana' : 'GY',
    'haiti' : 'HT',
    'heard island & mcdonald islands' : 'HM',
    'holy see (vatican city state)' : 'VA',
    'honduras' : 'HN',
    'hong kong' : 'HK',
    'hungary' : 'HU',
    'iceland' : 'IS',
    'india' : 'IN',
    'indonesia' : 'ID',
    'iran, islamic republic of' : 'IR',
    'iran' : 'IR',
    'iraq' : 'IQ',
    'ireland' : 'IE',
    'isle of man' : 'IM',
    'israel' : 'IL',
    'italy' : 'IT',
    'jamaica' : 'JM',
    'japan' : 'JP',
    'jersey' : 'JE',
    'jordan' : 'JO',
    'kazakhstan' : 'KZ',
    'kenya' : 'KE',
    'kiribati' : 'KI',
    'korea' : 'KR',
    'south korea' : 'KR',
    'kuwait' : 'KW',
    'kyrgyzstan' : 'KG',
    'lao people\'s democratic republic' : 'LA',
    'laos' : 'LA',
    'latvia' : 'LV',
    'lebanon' : 'LB',
    'lesotho' : 'LS',
    'liberia' : 'LR',
    'libyan arab jamahiriya' : 'LY',
    'libya': 'LY',
    'liechtenstein' : 'LI',
    'lithuania' : 'LT',
    'luxembourg' : 'LU',
    'macao' : 'MO',
    'macedonia' : 'MK',
    'north macedonia' : 'MK',
    'madagascar' : 'MG',
    'malawi' : 'MW',
    'malaysia' : 'MY',
    'maldives' : 'MV',
    'mali' : 'ML',
    'malta' : 'MT',
    'marshall islands' : 'MH',
    'martinique' : 'MQ',
    'mauritania' : 'MR',
    'mauritius' : 'MU',
    'mayotte' : 'YT',
    'mexico' : 'MX',
    'micronesia, federated states of' : 'FM',
    'micronesia' : 'FM',
    'moldova' : 'MD',
    'monaco' : 'MC',
    'mongolia' : 'MN',
    'montenegro' : 'ME',
    'montserrat' : 'MS',
    'morocco' : 'MA',
    'mozambique' : 'MZ',
    'myanmar' : 'MM',
    'myanmar (burma)' : 'MM',
    'namibia' : 'NA',
    'nauru' : 'NR',
    'nepal' : 'NP',
    'netherlands' : 'NL',
    'netherlands antilles' : 'AN',
    'new caledonia' : 'NC',
    'new zealand' : 'NZ',
    'nicaragua' : 'NI',
    'niger' : 'NE',
    'nigeria' : 'NG',
    'niue' : 'NU',
    'norfolk island' : 'NF',
    'northern mariana islands' : 'MP',
    'norway' : 'NO',
    'oman' : 'OM',
    'pakistan' : 'PK',
    'palau' : 'PW',
    'palestinian territory, occupied' : 'PS',
    'palestine': 'PS',
    'panama' : 'PA',
    'papua new guinea' : 'PG',
    'paraguay' : 'PY',
    'peru' : 'PE',
    'philippines' : 'PH',
    'pitcairn' : 'PN',
    'poland' : 'PL',
    'portugal' : 'PT',
    'puerto rico' : 'PR',
    'qatar' : 'QA',
    'reunion' : 'RE',
    'réunion' : 'RE',
    'romania' : 'RO',
    'russian federation' : 'RU',
    'russia' : 'RU',
    'rwanda' : 'RW',
    'saint barthelemy' : 'BL',
    'saint helena' : 'SH',
    'saint kitts and nevis' : 'KN',
    'saint lucia' : 'LC',
    'saint martin' : 'MF',
    'saint pierre and miquelon' : 'PM',
    'saint vincent and grenadines' : 'VC',
    'samoa' : 'WS',
    'san marino' : 'SM',
    'sao tome and principe' : 'ST',
    'saudi arabia' : 'SA',
    'senegal' : 'SN',
    'serbia' : 'RS',
    'seychelles' : 'SC',
    'sierra leone' : 'SL',
    'singapore' : 'SG',
    'slovakia' : 'SK',
    'slovenia' : 'SI',
    'solomon islands' : 'SB',
    'somalia' : 'SO',
    'south africa' : 'ZA',
    'south georgia and sandwich isl.' : 'GS',
    'spain' : 'ES',
    'sri lanka' : 'LK',
    'sudan' : 'SD',
    'south sudan' : 'SD',
    'suriname' : 'SR',
    'svalbard and jan mayen' : 'SJ',
    'swaziland' : 'SZ',
    'eswatini': 'SZ',
    'sweden' : 'SE',
    'switzerland' : 'CH',
    'syrian arab republic' : 'SY',
    'syrian' : 'SY',
    'syria' : 'SY',
    'taiwan' : 'TW',
    'tajikistan' : 'TJ',
    'tanzania' : 'TZ',
    'thailand' : 'TH',
    'timor-leste' : 'TL',
    'togo' : 'TG',
    'tokelau' : 'TK',
    'tonga' : 'TO',
    'trinidad and tobago' : 'TT',
    'trinidad & tobago' : 'TT',
    'tunisia' : 'TN',
    'turkey' : 'TR',
    'turkmenistan' : 'TM',
    'turks and caicos islands' : 'TC',
    'turks & caicos islands' : 'TC',
    'tuvalu' : 'TV',
    'uganda' : 'UG',
    'ukraine' : 'UA',
    'united arab emirates' : 'AE',
    'united kingdom' : 'GB',
    'united states' : 'US',
    'united states outlying islands' : 'UM',
    'uruguay' : 'UY',
    'uzbekistan' : 'UZ',
    'vanuatu' : 'VU',
    'venezuela' : 'VE',
    'viet nam' : 'VN',
    'vietnam' : 'VN',
    'virgin islands, british' : 'VG',
    'virgin islands, u.s.' : 'VI',
    'u.s. virgin islands': 'VI',
    'wallis and futuna' : 'WF',
    'western sahara' : 'EH',
    'yemen' : 'YE',
    'zambia' : 'ZM',
    'zimbabwe' : 'ZW',
};

export const getCode = (countryName) => {
    const code = countryMap[countryName.toLowerCase()];
    if (!code) {
        console.error(`Country ${countryName} NOT FOUND`);
    }
    return code;
}